import CtaBlock from "@components/ctaBlock";
import { Answer, AnswersList, Check } from "@components/variationSelect";
import { BlocksContent, Button } from "@global";
import { Query } from "@graphql-types";
import { Header } from "@shared";
import { MetlifeUnion } from "@state/types";
import { assets, colors, mediaQuery } from "@util/constants";
import { usePersonalisationHook } from "@util/personalisationHooks";
import { Container, PageWidth, Section } from "@util/standard";
import { PageProps, graphql } from "gatsby";
import React from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
interface Props extends PageProps {
  data: Query;
}

const Preferences = ({ data: { sanityHeader, allSanityPreferencesPage } }: Props) => {
  if (allSanityPreferencesPage.nodes.length === 0) return null;
  const {
    heroText,
    needHelpText,
    preferencesDisplaySetting,
    selectedVariations,
    moreInformationBlock,
  } = allSanityPreferencesPage.nodes[0];

  const handleToast = () => {
    toast.success("Your preferences have been saved");
  };

  return (
    <>
      <Header data={sanityHeader} />
      <Section noMargins>
        <PageWidth margin="100px auto 0 auto">
          <StyledPersonalisationWrapper
            flexDirection="row"
            columnGap="50px"
            isTabletColumn
            maxWidth="1440px"
          >
            <Container
              flexDirection="column"
              className="question-wrapper"
              width="50%"
              columnGap="50px"
              tabletWidth="100%"
            >
              <span className="personalisation-hero-block">
                {heroText && (
                  <span className="intro-block">
                    <BlocksContent data={heroText} />
                  </span>
                )}
              </span>
              {preferencesDisplaySetting === "showSelected" &&
                selectedVariations &&
                selectedVariations.length > 0 &&
                selectedVariations.map(variation => {
                  if (variation == null) return null;
                  const { answers, question, questionHeading } = variation;

                  const { selectedAnswer, handleAnswerSelect } = usePersonalisationHook(variation);

                  return (
                    <>
                      <p className="question" id="variation-selector">
                        {question}
                      </p>
                      <StyledAnswerBlock color={colors.cream} pageColor={colors.navy}>
                        {answers &&
                          answers.map(answerVariation => {
                            if (answerVariation == null) return null;
                            const { answer, marketoId } = answerVariation;

                            const isSelected = marketoId === selectedAnswer;

                            return (
                              <Answer
                                id="cream"
                                key={marketoId}
                                className={isSelected ? "active-answer" : ""}
                                onClick={() =>
                                  handleAnswerSelect(marketoId as MetlifeUnion | boolean)
                                }
                              >
                                <button>
                                  <Check selected={isSelected} aria-hidden="true">
                                    <img src={assets.tick} />
                                  </Check>
                                  <p className="answer-text">{answer}</p>
                                </button>
                              </Answer>
                            );
                          })}
                      </StyledAnswerBlock>
                    </>
                  );
                })}
              <Button
                colorvalue="#68BE5D"
                theme="colored"
                margin="50px 0 0 0"
                onClick={handleToast}
              >
                Save Preferences
              </Button>
            </Container>
            <Container margin="150px 0 0 0" mobileMargin="50px 0 0 0 ">
              <span></span>
              {needHelpText && (
                <span className="help-block">
                  <BlocksContent data={needHelpText} />
                </span>
              )}
            </Container>
          </StyledPersonalisationWrapper>
        </PageWidth>
        {moreInformationBlock && (
          <Container margin="120px 0 0 0">
            <CtaBlock data={moreInformationBlock} />
          </Container>
        )}
      </Section>
    </>
  );
};

export default Preferences;

export const query = graphql`
  query preferencePageQuery {
    sanityHeader {
      ...sanityHeader
    }
    allSanityVariation {
      nodes {
        ...sanityVariation
      }
    }
    allSanityPreferencesPage {
      nodes {
        heroText {
          ...sanityBlockContent
        }
        needHelpText {
          ...sanityBlockContent
        }
        preferencesDisplaySetting
        selectedVariations {
          ...sanityVariation
        }
        moreInformationBlock {
          ...sanityCtaBlock
        }
      }
    }
  }
`;

const StyledPersonalisationWrapper = styled(Container)`
  .personalisation-hero-block {
    margin-top: 150px;
    display: flex;
    justify-content: space-between;
    column-gap: 50px;

    h3 {
      margin: 0 !important;
    }

    .intro-block {
    }

    ${mediaQuery.tabletDown} {
      flex-direction: column;
      row-gap: 50px;
      margin-top: 90px;

      .help-block {
        padding: 30px;
      }
    }
  }
  .question {
    margin-top: 40px;
    font-weight: bold;
  }

  .help-block {
    max-width: 500px;
    height: fit-content;
    padding: 50px;
    background-color: ${colors.cream};
    position: sticky;
    top: 150px;

    h3 {
      margin: 0 !important;
    }
  }
`;

const StyledAnswerBlock = styled(AnswersList)`
  flex-direction: column;

  li {
    width: fit-content;
  }
`;
